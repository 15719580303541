






























































































































































import { debounce } from "@/helpers/debounce";
import { Api } from "@/models/class/api.class";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  LineDtosResponseDoReadyToPrint,
  ResponseListBastByIc,
} from "@/models/interface/salesOrder.interface";
import LocalStorageService from "@/services/LocalStorage.service";
import { salesOrderServices } from "@/services/salesorder.service";
import axios, { AxiosResponse } from "axios";
import printJS from "print-js";
import Vue from "vue";

interface DataForm {
  deliveryOrderNumber: string;
  deliveryOrderDate: string;
  supplierExpeditionCode: string;
  supplierExpeditionName: string;
  supplierExpeditionAddress: string;
  truk: string;
  description: string;
  transactionNumber: string;
  internalContractNumber: string;
  contractPeriod: string;
  customerName: string;
  customerShipTo: string;
  customerBillTo: string;
  phoneNumber: string;
  customerPicName: string;
  supplierAddress: string;
}

export default Vue.extend({
  data() {
    return {
      isAsset: false as boolean,
      form: this.$form.createForm(this, { name: "viewPrintBAST" }),
      dataSource: [] as LineDtosResponseDoReadyToPrint[],
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      totalData: 0 as number,
      loadingPrint: false as boolean,
      selectedRowKeys: [] as number[],
      listId: [] as (string | undefined)[],
      id: "" as string,
      dataForm: {
        deliveryOrderNumber: "",
        deliveryOrderDate: "",
        supplierExpeditionCode: "",
        supplierExpeditionName: "",
        supplierExpeditionAddress: "",
        truk: "",
        description: "",
        transactionNumber: "",
        internalContractNumber: "",
        contractPeriod: "",
        customerName: "",
        customerShipTo: "",
        customerBillTo: "",
        phoneNumber: "",
        customerPicName: "",
        supplierAddress: "",
      } as DataForm,
      columnsTable: [
        {
          title: this.$t("lbl_number_short"),
          dataIndex: "no",
          key: "no",
          width: 90,
          scopedSlots: { customRender: "no" },
        },
        {
          title: this.$t("lbl_product_code"),
          dataIndex: "productCode",
          key: "productCode",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_product_name"),
          dataIndex: "productName",
          key: "productName",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_location"),
          dataIndex: "customerLocation",
          key: "customerLocation",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_brand"),
          dataIndex: "merk",
          key: "merk",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_capacity"),
          dataIndex: "capacity",
          key: "capacity",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_bast_number"),
          dataIndex: "bastNumber",
          key: "bastNumber",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_last_print_by"),
          dataIndex: "lastPrintBy",
          key: "lastPrintBy",
          scopedSlots: { customRender: "isNull" },
        },
      ],
    };
  },
  methods: {
    // getCheckboxProps: record => ({
    //   props: {
    //     disabled: !record.isAsset ? true : false, // Column configuration not to be checked
    //     name: `isAsset`,
    //   },
    // }),
    onSelectChange(selectedRowKeys): void {
      this.listId = [];
      this.dataSource.forEach((dataForeach, index) => {
        if (selectedRowKeys.includes(index))
          this.listId = [...this.listId, dataForeach.id];
      });
      this.selectedRowKeys = selectedRowKeys;
    },
    getDetailDoPrint() {
      const params: RequestQueryParamsModel = {};
      salesOrderServices
        .getListDoReadyToPrintBast(params, this.id)
        .then(response => {
          this.isAsset = response.isAsset;
          this.dataSource = response.lineDTOS.map((data, index) => {
            return {
              ...data,
              key: index,
              no: index + 1,
              isAsset: response.isAsset,
            };
          });
          this.totalData = response.lineDTOS.length;
          this.dataForm.deliveryOrderNumber = response.doNumber;
          this.dataForm.deliveryOrderDate = response.doDate;
          this.dataForm.customerName = response.customerName;
          this.dataForm.customerShipTo = response.customerShipTo;
          this.dataForm.customerBillTo = response.customerBillTo;
          this.dataForm.phoneNumber = response.phoneNumber;
          this.dataForm.customerPicName = response.customerPicName;
          this.dataForm.supplierExpeditionCode =
            response.supplierExpeditionCode;
          this.dataForm.supplierExpeditionName =
            response.supplierExpeditionName;
          this.dataForm.supplierExpeditionAddress = response.supplierAddress;
          this.dataForm.truk = response.truckOrShipNo;
          this.dataForm.description = response.description;
          this.dataForm.transactionNumber = response.transactionNumber;
          this.dataForm.internalContractNumber = response.icNumber;
          this.dataForm.contractPeriod = response.contractPeriod;
          this.dataForm.supplierAddress = response.supplierAddress;
          if (this.selectedRowKeys.length > 0) {
            this.onSelectChange(this.selectedRowKeys);
          }
        });
    },
    getDetailIc() {
      const data = localStorage.getItem("dataIc") as string;
      const response: ResponseListBastByIc = JSON.parse(data);
      this.isAsset = response.isAsset;
      this.dataSource = response.lineDTOS.map((dataLineDtos, index) => {
        return {
          ...dataLineDtos,
          key: index,
          no: index + 1,
          isAsset: response.isAsset,
        };
      });
      this.totalData = response.lineDTOS.length;
      this.dataForm.deliveryOrderNumber = response.doNumber;
      this.dataForm.deliveryOrderDate = response.doDate;
      this.dataForm.customerName = response.customerName;
      this.dataForm.customerShipTo = response.customerShipTo;
      this.dataForm.customerBillTo = response.customerBillTo;
      this.dataForm.phoneNumber = response.phoneNumber;
      this.dataForm.customerPicName = response.customerPicName;
      this.dataForm.supplierExpeditionCode = response.supplierExpeditionCode;
      this.dataForm.supplierExpeditionName = response.supplierExpeditionName;
      this.dataForm.supplierExpeditionAddress = response.supplierAddress;
      this.dataForm.truk = response.truckOrShipNo;
      this.dataForm.description = response.description;
      this.dataForm.transactionNumber = response.transactionNumber;
      this.dataForm.internalContractNumber = response.icNumber;
      this.dataForm.contractPeriod = response.contractPeriod;
      this.dataForm.supplierAddress = response.supplierAddress;
      if (this.selectedRowKeys.length > 0) {
        this.onSelectChange(this.selectedRowKeys);
      }
    },
    async handlePrint() {
      try {
        if (!this.isAsset && this.dataSource.length > 0) {
          this.listId = [this.dataSource[0].id];
        }
        if (this.listId.length > 0) {
          const params: RequestQueryParamsModel = {
            page: this.page - 1,
            limit: this.limit,
          };
          this.loadingPrint = true;
          const token = `Bearer ${
            JSON.parse(LocalStorageService.loadUserAccessToken() as string)
              .value
          }`;
          const response: AxiosResponse<Blob> = await axios.get(
            Api.BastReady + `/report/bast/print/${this.listId.join(",")}`,
            {
              params,
              responseType: "arraybuffer",
              headers: { Authorization: token },
            }
          );
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            printJS(url);
          }
          this.loadingPrint = false;
        } else {
          this.$notification.error({
            message: "Error",
            description: "Select Data First to print",
          });
        }
      } catch {
        if (this.id && !this.id.includes("null")) {
          this.getDetailDoPrint();
        } else {
          this.getDetailIc();
        }
        debounce(() => {
          this.onSelectChange(this.selectedRowKeys);
        }, 1000);
        debounce(() => {
          this.handlePrint();
        }, 1500);
      }
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      // this.onSubmit()
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      // this.onSubmit()
    },
    handleBack(): void {
      this.$router.push({ name: "sales.minutes-of-handover.print" });
    },
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id && !this.id.includes("null")) {
      this.getDetailDoPrint();
    } else {
      this.getDetailIc();
    }
  },
  destroyed() {
    localStorage.removeItem("dataIc");
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 12 },
        wrapperCol: { span: 12 },
      };
    },
  },
});
