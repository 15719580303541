










import WarehouseTransferMutateForm from "@/components/WarehouseTransfer/WarehouseTransferMutateForm.vue";
import { WarehouseTransferMapper } from "@/mapper/WarehouseTransfer.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { CreateWarehouseTransferFormState } from "@/models/interface/warehouse-transfer/CreateWarehouseTransferFormState.interface";
import { RequestWarehouseTransfer } from "@/models/interface/warehouse.interface";
import { logisticServices } from "@/services/logistic.service";
import { Component, Mixins } from "vue-property-decorator";

@Component({
  components: {
    WarehouseTransferMutateForm,
  },
})
export default class CreateWarehouseTransfer extends Mixins(MNotification) {
  loading = false;

  handleBack(): void {
    this.$router.push({ name: "logistic.warehouse-transfer" });
  }

  handleCreate(formState: CreateWarehouseTransferFormState): void {
    const dto: RequestWarehouseTransfer =
      WarehouseTransferMapper.mapRequestWarehouseTransfer(formState);

    this.loading = true;
    logisticServices
      .submitWarehouseTransfer("-", dto)
      .then(res => {
        this.showNotifSuccess("notif_document_created_as_submit_success", {
          documentNumber: res.documentNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleSaveAsDraft(formState: CreateWarehouseTransferFormState): void {
    const dto: RequestWarehouseTransfer =
      WarehouseTransferMapper.mapRequestWarehouseTransfer(formState);

    this.loading = true;
    logisticServices
      .createWarehouseTransfer(dto)
      .then(res => {
        this.showNotifSuccess("notif_document_created_as_draft_success", {
          documentNumber: res.documentNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
