var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        title: _vm.$t("lbl_print_x", { x: _vm.$t("lbl_bast") })
                      }
                    },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      style:
                                        this.$store.state.innerWidth >= 992
                                          ? "margin:40px 0 0 0"
                                          : "margin: 0",
                                      attrs: {
                                        label: "Delivery Order Number :"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.deliveryOrderNumber
                                                ? _vm.dataForm
                                                    .deliveryOrderNumber
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Delivery Order Date :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.deliveryOrderDate
                                                ? _vm.dataForm.deliveryOrderDate
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: {
                                        label: "Supplier Expedition Code :"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm
                                                .supplierExpeditionCode
                                                ? _vm.dataForm
                                                    .supplierExpeditionCode
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: {
                                        label: "Supplier Expedition Name :"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm
                                                .supplierExpeditionName
                                                ? _vm.dataForm
                                                    .supplierExpeditionName
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Supplier Address :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.supplierAddress
                                                ? _vm.dataForm.supplierAddress
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Truk / Ship No. :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.truk
                                                ? _vm.dataForm.truk
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Description :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.description
                                                ? _vm.dataForm.description
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Transaction Number :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.transactionNumber
                                                ? _vm.dataForm.transactionNumber
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: {
                                        label: "Internal Contract Number :"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm
                                                .internalContractNumber
                                                ? _vm.dataForm
                                                    .internalContractNumber
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Contract Period :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.contractPeriod
                                                ? _vm.dataForm.contractPeriod
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Customer Name :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.customerName
                                                ? _vm.dataForm.customerName
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Customer Ship To :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.customerShipTo
                                                ? _vm.dataForm.customerShipTo
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Customer Bill To :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.customerBillTo
                                                ? _vm.dataForm.customerBillTo
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Phone Number :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.phoneNumber
                                                ? _vm.dataForm.phoneNumber
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { label: "Customer PIC Name :" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ant-form-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dataForm.customerPicName
                                                ? _vm.dataForm.customerPicName
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c("TableCustom", {
                                attrs: {
                                  dataSource: _vm.dataSource,
                                  columns: _vm.columnsTable,
                                  paginationcustom: false,
                                  defaultPagination: false,
                                  selectedRowKeys: _vm.selectedRowKeys,
                                  onSelectChange: _vm.onSelectChange,
                                  scroll: { x: "calc(700px + 50%)", y: 700 }
                                }
                              }),
                              _c("div", { staticClass: "text-right mt-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("lbl_total_items", {
                                        total: _vm.dataSource.length
                                      })
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24, align: "end" } },
                            [
                              _c(
                                "a-space",
                                [
                                  _c(
                                    "a-button",
                                    { on: { click: _vm.handleBack } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_back")) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.loadingPrint,
                                        icon: "printer"
                                      },
                                      on: { click: _vm.handlePrint }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_print")) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }