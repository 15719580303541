var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WarehouseTransferMutateForm", {
    attrs: {
      pending: _vm.loading,
      "data-testid": "create-warehouse-transfer-form"
    },
    on: {
      create: _vm.handleCreate,
      saveAsDraft: _vm.handleSaveAsDraft,
      back: _vm.handleBack
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }